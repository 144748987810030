import { apiNoAuthInstance } from "@apis";
import { getToken } from "@utils";
import { AUTH_TYPE, EApiUrl } from "enums";
import { config } from "process";
import {
  IBaseRes,
  ISignInAdminRes,
  IFormSignInAdmin,
  IAccessTokenRes,
} from "types";

export const verifyOTP = async (code: string, email: string) => {
  const { data } = await apiNoAuthInstance.post<IBaseRes<IAccessTokenRes>>(
    `${EApiUrl.AUTH_URL}${EApiUrl.ENDPOINT_VERIFY_OTP}`,
    {},
    {
      params: {
        code,
        email,
      },
    }
  );
  return data?.data;
};

export const signInAdmin = async ({ username, password }: IFormSignInAdmin) => {
  const { data } = await apiNoAuthInstance.post<IBaseRes<ISignInAdminRes>>(
    `${EApiUrl.AUTH_URL}${EApiUrl.ENDPOINT_SIGN_IN}`,
    { username, password }
  );
  return data?.data?.accessToken;
};

export const signOutAdmin = async () => {
  const { data } = await apiNoAuthInstance.post<IBaseRes>(
    `${EApiUrl.AUTH_URL}${EApiUrl.ENDPOINT_SIGN_OUT}`
  );
  return data;
};

export const verifyWithEmailOrSMS = async (
  type: AUTH_TYPE,
  payload: IFormSignInAdmin
) => {
  await apiNoAuthInstance.post<IBaseRes>(
    `${EApiUrl.AUTH_URL}${EApiUrl.ENDPOINT_REQUEST_OTP}`,
    payload,
    {
      params: { type },
    }
  );
};

export const getRefreshTokenService = async () => {
  const refreshToken = getToken("RefreshToken");
  const { data } = await apiNoAuthInstance.get<IBaseRes<IAccessTokenRes>>(
    `${EApiUrl.AUTH_URL}/refresh-token`,
    {
      headers: { Authorization: `Bearer ${refreshToken}` },
    }
  );
  return data?.data;
};

export const signInWithGoogleService = async (token: string) => {
  const res = await apiNoAuthInstance.post<IBaseRes<IAccessTokenRes>>(
    `${EApiUrl.AUTH_URL}/third-party`,
    undefined,
    {
      params: { token },
    }
  );
  return res?.data?.data;
};
