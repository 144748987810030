import { Button, FormControl, FormLabel, Stack, Text } from "@chakra-ui/react";
import { InputField } from "@components";
import { useGoogleLogin } from "@react-oauth/google";
import { getToken, storeAccessTokens } from "@utils";
import { AdminContext } from "context";
import { AUTH_TYPE, EToastStatus } from "enums";
import { useContext, useEffect, useMemo, useState } from "react";
import { FcGoogle } from "react-icons/fc";
import { IoEyeOffOutline, IoEyeOutline } from "react-icons/io5";
import { useLocation, useNavigate } from "react-router-dom";
import { signInWithGoogleService, verifyWithEmailOrSMS } from "services";
import { IFormSignInAdmin } from "types";

const btnLoginClasses =
  "mt-5 w-full bg-brand-500 text-base font-medium text-white hover:bg-brand-600 disabled:bg-gray-300 disabled:text-gray-900 dark:bg-white dark:text-navy-600 dark:hover:bg-navy-600 dark:hover:text-white dark:disabled:hover:bg-gray-300 dark:disabled:hover:text-gray-900";
const btnLoginGoogleClasses =
  "mt-5 w-full border text-base font-medium shadow hover:shadow-none disabled:bg-gray-300 disabled:text-gray-900 dark:bg-white dark:text-navy-600 dark:hover:bg-navy-600 dark:hover:text-white dark:disabled:hover:bg-gray-300 dark:disabled:hover:text-gray-900";

export const SignIn = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const email = location.state?.email;
  const { showToast } = useContext(AdminContext);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isValid, setIsValid] = useState(true);
  const [loginForm, setLoginForm] = useState<IFormSignInAdmin>({
    username: email ?? "",
    password: "",
  });
  const [showPassword, setShowPassword] = useState(false);
  const [isThirdPartyLoginPending, setThirdPartyLoginPending] = useState(false);

  useEffect(() => {
    if (loginForm.username && loginForm.password) setIsValid(true);
  }, [loginForm]);

  useEffect(() => {
    const token = getToken("AccessToken");
    if (token) {
      navigate("/admin");
    }
  }, []);

  const isDisabledForm = useMemo(
    () => isSubmitting || isThirdPartyLoginPending,
    [isThirdPartyLoginPending, isSubmitting]
  );

  const handleSignIn = async (type: AUTH_TYPE) => {
    if (!loginForm.username || !loginForm.password) return setIsValid(false);
    try {
      setIsSubmitting(true);
      await verifyWithEmailOrSMS(type, loginForm);
      navigate("/auth/verify-otp", { state: { email: loginForm.username } });
    } catch (error: any) {
      setIsValid(false);
    } finally {
      setIsSubmitting(false);
    }
  };

  const loginGoogle = useGoogleLogin({
    onSuccess: async (response) => {
      try {
        const tokens = await signInWithGoogleService(
          response.access_token
        );
        storeAccessTokens(tokens);
        navigate("/admin");
      } catch (error: any) {
        showToast("Error", error?.response?.data?.message, EToastStatus.ERROR);
      }
      setThirdPartyLoginPending(false);
    },
    onError: () => {
      setThirdPartyLoginPending(false);
    },
    onNonOAuthError: () => {
      setThirdPartyLoginPending(false);
    },
  });

  const onClickLoginGoogle = () => {
    setThirdPartyLoginPending(true);
    loginGoogle();
  };

  const handleOnPress = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    type: AUTH_TYPE
  ) => {
    e.preventDefault();
    handleSignIn(type);
  };

  return (
    <div className="mb-16 mt-16 flex h-full w-full items-center justify-center px-4 md:mx-0 md:px-6 lg:mb-10 lg:items-center lg:justify-center">
      <div className="mt-[10vh] w-full max-w-full flex-col items-center lg:pl-0 xl:max-w-[420px]">
        <h4 className="mb-2.5 text-4xl font-bold text-navy-700 dark:text-white">
          Sign In
        </h4>
        <p className="mb-9 ml-1 text-gray-600">
          Enter your username and password, or continue with{" "}
          <strong className="cursor-pointer" onClick={onClickLoginGoogle}>
            Google
          </strong>{" "}
          to sign in!
        </p>
        <form>
          <Stack spacing={4}>
            <FormControl>
              <FormLabel className="dark:text-white" htmlFor="username">
                Username*
              </FormLabel>
              <InputField
                id="username"
                type="username"
                value={loginForm.username}
                placeholder="Enter your username"
                className="bg-white dark:bg-navy-600 dark:text-white"
                disabled={isDisabledForm}
                onChange={(e) =>
                  setLoginForm({ ...loginForm, username: e.target.value })
                }
              />
            </FormControl>
            <FormControl>
              <FormLabel className="dark:text-white" htmlFor="password">
                Password*
              </FormLabel>
              <InputField
                id="password"
                type={showPassword ? "text" : "password"}
                placeholder="Enter your password"
                className="bg-white dark:bg-navy-600 dark:text-white"
                disabled={isDisabledForm}
                value={loginForm.password}
                onChange={(e) =>
                  setLoginForm({ ...loginForm, password: e.target.value })
                }
                iconRight={
                  showPassword ? (
                    <IoEyeOffOutline
                      size={25}
                      className="cursor-pointer"
                      onClick={() => setShowPassword(!showPassword)}
                    />
                  ) : (
                    <IoEyeOutline
                      size={25}
                      className="cursor-pointer"
                      onClick={() => setShowPassword(!showPassword)}
                    />
                  )
                }
              />
            </FormControl>
            {!isValid && (
              <Text color="red.500">
                The username or password you entered is incorrect
              </Text>
            )}
          </Stack>
          <div className="flex flex-row gap-9">
            <Button
              isLoading={isSubmitting}
              variant="brandPrimary"
              className={btnLoginClasses}
              type="submit"
              isDisabled={isDisabledForm || !isValid}
              onClick={(e) => {
                handleOnPress(e, AUTH_TYPE.SMS);
              }}
            >
              Send SMS
            </Button>
            <Button
              isLoading={isSubmitting}
              variant="brandPrimary"
              className={btnLoginClasses}
              type="submit"
              isDisabled={isDisabledForm || !isValid}
              onClick={(e) => {
                handleOnPress(e, AUTH_TYPE.EMAIL);
              }}
            >
              Send Email
            </Button>
          </div>
          <Button
            variant="brandPrimary"
            className={btnLoginGoogleClasses}
            leftIcon={<FcGoogle />}
            isDisabled={isDisabledForm}
            onClick={onClickLoginGoogle}
          >
            Continue with Google
          </Button>
        </form>
      </div>
    </div>
  );
};
